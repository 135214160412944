<template>
  <section class="full-screen">
    <div class="is-overlay" v-if="showFullPage">
      <div class="bg-container"></div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#0e3d4d"
          fill-opacity="1"
          d="M0,256L48,234.7C96,213,192,171,288,154.7C384,139,480,149,576,160C672,171,768,181,864,197.3C960,213,1056,235,1152,240C1248,245,1344,235,1392,229.3L1440,224L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
    </div>
    linear-gradient(rgba(0, 0, 0, 0), #0e3d4de3)
    <div
      class="text-on-top is-justify-content-center container is-max-desktop"
      :class="{'has-text-black mt-4': !showFullPage,
    'mt-6': showFullPage	}"
    >
      <div
        class="is-flex is-align-items-center mb-5 is-justify-content-center"
        :class="{'mt-4': !showFullPage,
        'mt-6': showFullPage	}"
      >
          <img :src="bellIcon" class="logo mt-6 mr-2"/> 
        <p class="is-size-3 has-text-weight-bold"
        :class="{'white-text': showFullPage	}">
        </p>
      </div>

      <p class="mb-4"
      :class="{'white-text': showFullPage	}">
        Our concierge service is perfect for those short on time or resources
        for self-planning. Let us handle your specific preferences and lay the
        groundwork for your dream trip in Portugal.
      </p>

      <p class=" mb-6"
      :class="{'white-text': showFullPage	}">
        Our service includes research, a custom proposal, and help with the
        booking and organization of your trip by our Specialists.
      </p>

      <b-table
        :class="{'pb-6': showFullPage	}"
        :data="data"
        striped
        hoverable
        :mobile-cards="false"
      >
        <b-table-column
          field="type"
          label="Our concierge service offers:"
          v-slot="props"
        >
          <span class="is-flex is-align-items-center">
            {{ props.row.type }}

            <b-tooltip
              v-if="props.row?.tooltip?.length > 1"
              position="is-right"
              multilined
            >
              <img
                src="./assets/circle-info-solid.png"
                class="icon--sm ml-2 mt-1"
              />

              <template v-slot:content>
                {{ props.row?.tooltip }}
              </template>
            </b-tooltip>
          </span>
        </b-table-column>

        <b-table-column
          field="booking_of"
          label="Booking of"
          centered
          v-slot="props"
        >
          <img
            v-if="props.row.booking_of"
            class="icon"
            src="./assets/check--green.png"
          />
        </b-table-column>

        <b-table-column
          field="assistance_with"
          label="Assistance with"
          centered
          v-slot="props"
        >
          <img
            v-if="props.row.assistance_with"
            class="icon"
            src="./assets/check--green.png"
          />
        </b-table-column>
      </b-table>
      <div v-if="showFullPage">
        <div
          class="steps mt-6"
          v-for="(step, index) in steps"
          :key="step.title"
        >
        <img :src="step.img" v-if="step.img" class="step-img mb-6 mt-3" />
          <div
            class="is-flex is-align-items-center mb-4 is-justify-content-center"
          >
            <div class="tag mr-2">Step {{ index + 1 }}</div>
            <b>{{ step.title }}</b>
          </div>

         
          <p class="mb-3">{{ step.description }}</p>
          <b-button
            tag="router-link"
            to="/concierge/quote"
            type="is-success is-light"
            v-if="index === 0"
            class="mt-4"
          >
            <span class="is-flex is-align-items-center">
              Fill out request form
            </span>
          </b-button>

          <br />
          <img class="mt-5" v-if="index + 1 < steps.length" :src="lineImage" />
        </div>
      </div>

      <div v-if="showFullPage" class="has-content-centered pt-6 mt-6 mb-6">
        <p class="has-text-weight-semibold is-size-5">
          Let's plan the perfect trip together!
        </p>
        <b-button
          type="is-primary"
          class="mt-4 mb-6"
          tag="router-link"
          to="/concierge/quote"
          >Fill out the request form</b-button
        >
      </div>

      <div v-if="suggestedActivities.length && showFullPage" class="mb-6 mt-6">
        <p class="card-header-title subtitle mt-4 mb-5 is-size-5">
         Get inspired
        </p>
        <TicketsAndActivitiesContainer
          :showMore="false"
          class="mt-3 mb-4"
          :is-full-width="false"
          :is-experiences-ids="false"
          :Experiences="suggestedActivities"
          :Tickets="[]"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { HelpersMixin } from "./mixins";
import { mapState, mapActions } from "vuex";
import { ActivitiesStates, ActivitiesActions } from './store/Storetypes'
import TicketsAndActivitiesContainer from "./components/TicketsAndActivitiesContainer.vue";

//import VueLottie from 'vue-lottie';
export default {
   components: {
        TicketsAndActivitiesContainer
    },
  props: {
    showFullPage: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [HelpersMixin],
  head() {
    return {
      title: "Concierge Services in Portugal - Guiders",
      link: [{ rel: "canonical", href: "https://guiders.pt/concierge" }],
      meta: [
        {
          name: "description",
          content:
            "Explore our top-notch concierge services in Portugal. We offer personalized experiences and seamless travel solutions for discerning travelers.",
        },
        {
          name: "keywords",
          content:
            "concierge services, Portugal travel, personalized travel, travel solutions, luxury travel",
        },
        { name: "author", content: "Guiders" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          property: "og:title",
          content: "Concierge Services in Portugal - Guiders",
        },
        {
          property: "og:description",
          content:
            "Discover exceptional concierge services in Portugal with Guiders. Enjoy tailored experiences and premium travel solutions.",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://guiders.pt/concierge" },
      ],
    };
  },
  data() {
    const data = [
      {
        type: "Accommodation",
        booking_of: true,
        assistance_with: true,
      },
      {
        type: "Transfers",
        booking_of: true,
        assistance_with: true,
      },
      {
        type: "Local guide",
        booking_of: true,
        assistance_with: true,
      },
      {
        type: "Activities",
        booking_of: true,
        assistance_with: true,
      },
      {
        type: "Private transportation",
        booking_of: true,
        assistance_with: true,
        tooltip:
          "We will happily level up your experience with a luxurious vehicle and private driver through one of our partners.",
      },
      {
        type: "Insurance",
        booking_of: false,
        assistance_with: true,
        tooltip:
          "In collaboration with our partner, we can provide you full travel insurance for your trip.",
      },
      {
        type: "Itinerary planning",
        booking_of: false,
        assistance_with: true,
      },
      {
        type: "Restaurant suggestions",
        booking_of: false,
        assistance_with: true,
      },
      {
        type: "Restaurant reservations",
        booking_of: true,
        assistance_with: true,
      },
    ];
    return {
      data,
      /*  lottieOptions: {
                animationData: require('@/assets/animations/circle.json'),
                loop: true,
                autoplay: true,
            }, */
      steps: [
        {
          title: "Fill out the request form",
          img: require("./assets/s1.png"),
          description:
            "We’ll collect information about your needs and preferences. If required, we will contact you via email to clarify further details.",
        },
        {
          title: "Building the itinerary",
          description:
            "During this step, we can help you organize onsite logistics, transfers, food, and fun local activities. Looking for something specific or unusual for your trip? We are here for that too, happy to further tailor our services to meet your unique needs!",
        },
        {
          title: "Your custom proposal",
          img: require("./assets/s2.png"),
          description:
            "We’ll do the research to prepare a personalized proposal tailored around your specific requirements.",
        },
        {
          title: "Booking and payment",
          img: require("./assets/s3.png"),
          description:
            "Once you’re happy with your proposal, we can move onto securing and booking accommodation, transportation, private guides and everything else you’ll need.",
        },
        {
          title: "Post-purchase support",
          description:
            "Do you need to make any changes to your reservation? What about organizing a car rental? Or getting some tips for local cuisine? We provide ongoing guidance and advice for your trip.",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getActivities()
  },
  computed: {
    ...mapState({
      activities: (state) => state.activities[ActivitiesStates.ACTIVITIES],
    }),
    lineImage() {
      return require("./assets/line-vertical.png");
      //  return this.isMobileView ? require('./assets/line-vertical.png') : require('./assets/line-horizontal.png')
    },
    bellIcon(){
      return !this.showFullPage ? require('./assets/bell--dark.png') :  require('./assets/logo_concierge.svg')
    },
    suggestedActivities(){
      // uncomment when we have multiday tours
      /* const suggestionsIds = []
      const filteredObjects = this.activities.filter(obj => suggestionsIds.includes(obj.id));
    return filteredObjects */
    const activityId = 13
      const otherActivities = this.activities.filter(
        (activity) => activity.categories.includes(activityId)
      );
      return otherActivities
    }
  },

  methods: {
    ...mapActions({
            getActivities: ActivitiesActions.GET_ACTIVITIES
        }),
  }
};
</script>

<style lang="scss" scoped>
.step-img{
  max-height: 18rem;
}
.has-content-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #edfcff;
  border-radius: 8px;
}
.logo {
  height:3.5rem;
  width: auto;
}
.white-text {
  color: white;
  font-size: 1.1rem;
  text-align: center;
}
.bg-container {
  width: 100%;
  height: 34rem;
  background-color: #0e3d4d;
}
svg {
  margin-bottom: 20rem;
}
.tag {
  color: white;
  background-color:#0e3d4d;
}

.steps {
  margin: 0 1rem;
  text-align: center;
}

.text-on-top {
  padding: 0 1rem;
  inset-area: center;
}

.icon--sm {
  height: 1.25rem;
  width: 1.25rem;
}
.app-page:not(.full-screen) {
  padding: 0 !important;
  display: block;
  min-height: 100vh;
}
::v-deep {
  div.container {
    max-width: 800px !important;
  }
  .app-page:not(.full-screen) {
    padding: 0 !important;
  }
  .b-table .table {
    border: none;
  }
  .b-table .table th {
    color: white;
    background-color: #326779;
  }
  .table thead th {
    border-width: 0;
  }
}
</style>
