export const data= 
    {
        id: "1",
        title: "Best of Lisbon & Porto - 7 Days",
        price: "1200",
        deposit: "50",
        images: [],
        duration: "5 Days",
        cities: "Lisbon, Sintra, Porto, Aveiro, Fátima, Tomar",
        description:"Discover Portugal's two largest cities on a magnificent 7-day itinerary that will take you to explore the main attractions of Porto and Lisbon with the accompaniment of experienced and certified guides.",
       
        whatsIncluded: `
        <li> Private Transfers </li>
        <li> Private Drivers </li>
        <li> Licensed Guides </li>
        <li> Continual monitoring of your journey by a Guiders team member</li>
        <li> Fully Customizable Itinerary </li>
        <li> Restaurant reservations for big groups </li>
        `,
        whatsNotIncluded: `
        <li> International airfare</li>
        <li> Expenses of a personal nature</li>
        <li> Gratuities</li>
        `,
       /*  cancellationPolicy: `Deposits are non-refundable. Any changes made less than 31 days before the tour start time will not be accepted. The cut-off times are based on the local time of the tour. If an experience is cancelled due to weather conditions or any other reason, you will be given a different date/experience or a full refund.
        `, */
        highlights: [
            "Experience the raw emotion of Fado in an intimate setting",
            "Taste wines at an 18th-century estate in Sintra",
            "Discover the charming towns of Estoril, Cascais and Sintra",
            "Tour the city of Porto on foot and by boat",
            "Navigate Lisbon’s highlights on an exhilarating Tuk Tuk Tour",
            "Explore Tomar and Fatima with a knowledgeable local guide"
        ],
        itinerary: [
            {
                title: 'Day 1: Lisbon – The Heart of Portugal',
                text: `<h4 class="is-underlined">Morning:</h4>
                <p class="mt-3"><strong>Private Walking Tour of Alfama & Baixa</strong><br>
                Begin your day with a private guided walking tour through Lisbon's oldest neighborhood, Alfama. Explore its narrow streets, charming squares, and historic sites like the São Jorge Castle and Lisbon Cathedral. Continue to Baixa, Lisbon's bustling downtown, with its neoclassical architecture and iconic squares.</p>

                <h4 class="mt-5 is-underlined">Afternoon:</h4>
                <p class="mt-3"><strong>Tuk-Tuk Ride through Lisbon’s Highlights</strong><br>
                After lunch, hop on a private tuk-tuk for an exciting ride through Lisbon’s most vibrant neighborhoods. The tuk-tuk will take you to iconic landmarks like the Praça do Comércio, the colorful streets of Bairro Alto, and the scenic viewpoints of Miradouro de Santa Catarina. The tuk-tuk ride offers a fun and effortless way to see the city's best spots with insider commentary from your guide.</p>
                
                <p class="mt-3"><strong>Private Tour of Belém</strong><br>
                Continue your exploration with a private transfer to Belém. Visit the Jerónimos Monastery and Belém Tower, both UNESCO World Heritage Sites, and learn about Portugal’s Age of Discovery. Don’t miss tasting the original "Pastéis de Belém" at the famous bakery.</p>
                
                <h4 class="mt-5 is-underlined">Evening:</h4>
                <p class="mt-3"><strong>Fado Night in Bairro Alto</strong><br>
                End your day with an authentic Fado performance in Bairro Alto. A private driver will take you to a traditional Fado restaurant where you’ll enjoy soulful music over a delicious dinner.</p>`,
            },
            {
                title: 'Day 2: Sintra & Cascais – Fairytale Palaces and Coastal Charm',
                text: `<h4 class="is-underlined">Morning:</h4>
                <p class="mt-3"><strong>Private Guided Tour of Sintra</strong><br>
                Depart from Lisbon with your private guide and driver to Sintra. Explore the magical Pena Palace, perched high on a hill, followed by a visit to the mysterious Quinta da Regaleira, known for its fascinating gardens and initiation well.</p>

<h4 class="mt-5 is-underlined">Lunch:</h4>
                <p class="mt-3"><strong>Lunch at a Local Restaurant in Sintra</strong><br>
                Savor local flavors at a traditional Sintra restaurant. Enjoy regional specialties like "Travesseiros de Sintra" (almond pastries) and seafood.</p>


                <h4 class="mt-5 is-underlined">Afternoon:</h4>
                <p class="mt-3"><strong>Explore Cascais</strong><br>
                Continue your journey to Cascais, a charming coastal town. Take a scenic drive along the Atlantic coast, stopping at Boca do Inferno, a dramatic cliff formation. Explore the town's picturesque streets and relax at the beach or a local café.</p>
                
                <p class="mt-3"><strong>Sunset at Cabo da Roca</strong><br>
                End the day with a private sunset tour to Cabo da Roca, the westernmost point of mainland Europe. Your driver will return you to Lisbon after enjoying the stunning ocean views.
</p>` ,
            },
            {
                title: 'Day 3: Porto – Wine, History, and Scenic Views',
                text: `<h4 class="is-underlined">Morning:</h4>
                <p class="mt-3"><strong>Private Tour of Porto’s Historic Center</strong><br>
                Arrive in Porto with a private transfer. Start your day with a guided walking tour of Porto's historic center, a UNESCO World Heritage Site. Visit landmarks like the Clérigos Tower, São Bento Railway Station, and the Ribeira district along the Douro River.</p>

<h4 class="mt-5 is-underlined">Lunch:</h4>
                <p class="mt-3"><strong>Lunch at a Riverside Restaurant</strong><br>
                Enjoy lunch with a view of the Douro River, savoring local specialties like "Francesinha," a hearty sandwich dish unique to Porto.</p>


                <h4 class="mt-5 is-underlined">Afternoon:</h4>
                <p class="mt-3"><strong>Private Wine Tasting at a Port Wine Cellar</strong><br>
                In the afternoon, take a private tour of one of Porto’s famous Port wine cellars in Vila Nova de Gaia. Learn about the wine-making process and enjoy a curated tasting of different Port wines.</p>
                
                
                <h4 class="mt-5 is-underlined">Evening:</h4>
                <p class="mt-3"><strong>Relax with a scenic dinner cruise along the Douro River, taking in the city lights and historic bridges as you enjoy a gourmet meal.
</p>`,
          
            },
            {
                title: 'Day 4: Aveiro – The Venice of Portugal',
                text: `<h4 class="is-underlined">Morning:</h4>
                <p class="mt-3"><strong>Private Guided Tour of Aveiro</strong><br>
                Depart for Aveiro with a private guide and driver. Explore the picturesque canals of this charming town, often called the "Venice of Portugal." Enjoy a guided "moliceiro" boat ride through the canals and see the town's colorful Art Nouveau architecture.</p>

<h4 class="mt-5 is-underlined">Lunch:</h4>
                <p class="mt-3"><strong>Seafood Lunch in Costa Nova</strong><br>
               Travel to Costa Nova, famous for its striped beach houses, for a fresh seafood lunch by the beach.</p>


                <h4 class="mt-5 is-underlined">Afternoon:</h4>
                <p class="mt-3"><strong>Explore Aveiro’s Salt Pans and Museums</strong><br>
                Visit the salt pans where traditional sea salt is harvested, followed by a tour of the Aveiro Museum, housed in a former convent, to learn about the town’s history.</p>
                
                
                <h4 class="mt-5 is-underlined">Evening:</h4>
                <p class="mt-3"><strong>Return to Porto for a Relaxing Evening</strong><br>
Head back to Porto in the evening. Enjoy free time to explore the city on your own or relax at your hotel.
</p>`,
          
            },
            {
                title: 'Day 5: Fátima and Tomar – Spirituality and Historical Wonders',
                text:`<h4 class="is-underlined">Morning:</h4>
                <p class="mt-3"><strong>Private Guided Tour of Fátima</strong><br>
               Begin your day with a private transfer to Fátima, a major pilgrimage site. Explore the Sanctuary of Fátima, where the Marian apparitions took place in 1917. Visit the Basilica of Our Lady of the Rosary, the Chapel of Apparitions, and the Basilica of the Holy Trinity. Your guide will provide insights into the history and significance of these sacred sites.</p>

<h4 class="mt-5 is-underlined">Lunch:</h4>
                <p class="mt-3"><strong>Lunch at a Local Restaurant in Fátima</strong><br>
              Enjoy lunch at a local restaurant in Fátima, offering traditional Portuguese cuisine. Experience a peaceful meal in a serene setting, reflecting on the morning’s visit.</p>


                <h4 class="mt-5 is-underlined">Afternoon:</h4>
                <p class="mt-3"><strong>Explore Tomar – The Convent of Christ</strong><br>
                Travel to Tomar, a historic town known for its impressive Convent of Christ, a UNESCO World Heritage Site. This former Knights Templar stronghold features stunning medieval architecture, including the ornate Chapter House and the unique Manueline window. Your guide will share the fascinating history of the Knights Templar and the architectural evolution of the convent.</p>

<p class="mt-3"><strong>Stroll Through Tomar’s Historic Center</strong><br>
               After touring the Convent of Christ, take a leisurely stroll through Tomar’s charming historic center. Explore the picturesque streets, the historic bridge over the Nabão River, and the lively Praça da República. Enjoy the local atmosphere and perhaps a coffee at a traditional café.</p>

                
                
                <h4 class="mt-5 is-underlined">Evening:</h4>
                <p class="mt-3"><strong>Return to Lisbon and Dinner at a Gourmet Restaurant</strong><br>
Return to Lisbon in the evening with a private transfer. For your final dinner, enjoy a gourmet meal at a renowned restaurant such as Bairro do Avillez or Cervejaria Ramiro. Indulge in a refined dining experience that highlights the best of Portuguese cuisine.
</p>`,
          
            },
        ]
    }
