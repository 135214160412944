<template>
  <section class="container is-fullhd">
    <div>
      <div id="content">
        <GoBack class="back--btn icon-circle" />
        

        <h1 class="title mb-6 font-md" :class="{ 'mt-5 pt-2': isMobileView }">
          {{ tourData.title }}
        </h1>

        <div class="columns is-mobile">
          <div class="mb-2 column" v-if="tourData.duration">
            <p class="subtitle-md mb-3">Duration</p>
            <p class="description">
              {{ tourData.duration }}
            </p>
          </div>

          <div class="mb-2 column" v-if="tourData.duration">
            <p class="subtitle-md mb-3">Destinations</p>
            <p class="description">
              {{ tourData.cities }}
            </p>
          </div>
        </div>

        <p class="description-text mb-6" v-html="tourData.description">
          {{ tourData.description }}
        </p>

        <Reviews :Reviews="tourData.reviews" class="mb-6" />
        <div
          v-for="(collapse, index) of collapsableContent"
          :key="index"
          class="mb-6"
        >
          <section class="mb-5" v-if="collapse.text">
            <p class="subtitle-md mb-3">
              {{ collapse.title }}
            </p>

            <span class="description" v-html="collapse.text">
              {{ collapse.text }}</span
            >
          </section>
        </div>

        <section class="highlights-section">
          <p class="mb-5 subtitle has-text-centered">Trip highlights</p>
          <div class="pt-5 pb-5" :class="{ 'mr-6 ml-6': !isMobileView }">
            <div class="columns is-multiline">
              <div
                class="column is-6"
                v-for="highlight in tourData.highlights"
                :key="highlight"
              >
                <div class="is-flex is-align-items-flex-start">
                  <img src="../assets/star.svg" class="mr-2 mt-1 icon" />
                 <span class="description">
{{ highlight }}
                 </span> 
                </div>
              </div>
            </div>
          </div>

          <div class="scrollable-container">
            <div class="image-wrapper">
              <div class="image--container">
                <img class="card--image" src="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Fimage%20204.png?alt=media&token=1a02460d-4bd6-4a52-a5d9-bf08fb677431" alt="Image 1">

                <div class="is-flex is-align-items-center img--text mr-2">
                  <img src="../assets/location-icon.svg" class="mr-1 icon" />
                  <span class="text">Pena Palace</span>
              </div>
              </div>
              
               
                <img src="https://descubralisboa.com/wp-content/uploads/2018/10/Casa-dos-Bicos_2.jpg" alt="Image 2">
                <img src="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Fimage%20207.png?alt=media&token=e5a1e19e-9ca1-4331-8566-e7575ff06c22" alt="Image 3">
               
            </div>
        </div>
        </section>

        <b-collapse
            animation="slide"
            v-for="(collapse, index) of tourData.itinerary"
            :key="index"
            :open="isOpen == index"
            @open="isOpen = index"
            :aria-id="'contentIdForA11y5-' + index">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button"
                    :aria-controls="'contentIdForA11y5-' + index"
                    :aria-expanded="props.open"
                >
                    <p class="card-header-title">
                        {{ collapse.title }}
                    </p>
                    <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'chevron-down' : 'chevron-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <div v-html="collapse.text">
                    {{ collapse.text }}
                </div>
            </div>
        </b-collapse>

        <section>
          <p class="subtitle has-text-centered">The best of <span class="handwritten is-size-2">Porto</span></p>
        </section>

       
        <!-- <div v-if="similarActivities.length" class="mb-3 mt-6">
                <p class="card-header-title subtitle mt-4 mb-5 is-size-5">
                  You might also like
                </p>
                <TicketsAndActivitiesContainer
                  :showMore="false"
                  class="mt-3"
                  :is-full-width="false"
                  :is-experiences-ids="false"
                  :Experiences="similarActivities"
                  :Tickets="[]"
                />
              </div> -->
       
        <TourFAQ class="faq" />
      </div>
    </div>

    <footer class="page-footer is-hidden-desktop">
      <div class="row footer-mobile">
        <div
          class="columns is-mobile"
          :class="{ 'mt-1 mb-1': !tourData.price }"
        >
          <div
            class="column"
            :class="{ 'is-5': !tourData.price, 'is-7': tourData.price }"
          >
            from
            <span class="price">
              {{
                !tourData.isPrivateOnly
                  ? tourData.price
                  : tourData.privatePrice
              }}€
            </span>
            <p class="label mt-1 mr-2" v-if="tourData.price">
              Reserve your tour with a small deposit.
            </p>
          </div>
          <b-button
            class="is-large mr-3"
            type="is-primary"
            icon-right="arrow-right"
            expanded
            rounded
            @click="isBookingModalActive = true"
          >
            {{ tourData.price ? "Book now" : "Reserve your spot" }}
          </b-button>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import { data } from "./Tours/LisbonAndPorto";
import { HelpersMixin } from "../mixins";

export default {
  mixins: [HelpersMixin],
  data() {
    return {
      isOpen: 0,
      lottieOptions: {
        animationData: require("@/assets/animations/info-an.json"),
        loop: true,
        autoplay: true,
      },
      tourData: data,
      showMore: false,
      images: [
        {
          largeURL:
            'https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg',
          thumbnailURL:
            'https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-200.jpg',
          width: 1875,
          height: 2500,
        },
        {
          largeURL:
            'https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-2500.jpg',
          thumbnailURL:
            'https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-200.jpg',
          width: 1669,
          height: 2500,
        },
        {
          largeURL:
            'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg',
          thumbnailURL:
            'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg',
          width: 2500,
          height: 1666,
        },
      ],
    };
  },
  mounted() {
    console.log(this.tourData);
  },
  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
  },
  computed: {
    collapsableContent() {
      if (!this.tourData) return [];
      const content = [];
      if (this.tourData?.whatsIncluded.length) {
        content.push({
          title: "What's included",
          text: this.tourData?.whatsIncluded,
        });
      }
      if (this.tourData?.whatsNotIncluded.length) {
        content.push({
          title: "What's not included",
          text: this.tourData?.whatsNotIncluded,
        });
      }

     /*  if (this.tourData?.cancellationPolicy.length) {
        content.push({
          title: "Cancellation Policy",
          text: this.tourData?.cancellationPolicy,
        });
      } */

      /*  
     if (this.tourData?.additionalInfo) {
        content.push({
          title: "Additional information",
          text: this.tourData?.additionalInfo,
        });
      }
      if (this.tourData?.notSuitableFor) {
        content.push({
          title: "Not suitable for",
          text: this.tourData?.notSuitableFor,
        });
      }
      if (this.tourData?.accessibility) {
        content.push({
          title: "Accessibility",
          text: this.tourData?.accessibility,
        });
      } */
      return content;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

.image--container{
  position: relative;
  
  .icon{
    height: 1rem;
    width: 1rem
  }
}

.card--image {
  height: 200px;
  border-radius: 16px;
  width: -webkit-fill-available;
  object-fit: cover;
}

.scrollable-container {
  width: 100%;
  height: 400px; /* Adjust the height as needed */
  overflow-x: scroll;
  overflow-y: hidden; /* Prevent vertical scroll */
  display: flex;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
}

.subtitle-md{
  font-size: 1.1rem;
  font-weight: 600;
  color: #124F69;
}

.image-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Align items to the start of the container */
  gap: 1rem; /* Space between images */
  .image--container::after{
      position: absolute;
    background: transparent linear-gradient(to bottom, #FFFFFF 0%, #000000 100%) 0% 0% no-repeat padding-box;
  
  }
}

.image-wrapper img {
  width: 280px; /* Set the width of images */
  height: auto; /* Maintain aspect ratio */
  display: block;
 
}

/* Alternate margin-top using nth-child */
.image-wrapper img:nth-child(odd) {
  margin-top: 0;
}

.image-wrapper img:nth-child(even) {
  margin-top: 3rem;
}


.icon--sm {
  height: 0.75rem;
  width: 0.75rem;
}

.img--text{
  background-color: #f2f6d0;
  border-radius: 20px 20px 20px 0;
  padding: 4px 12px;
  font-weight: 600;
  color: #0e3d4d;
  width: -moz-max-content;
  width: max-content;
  position: absolute;
  bottom: 12px;
  left: 12px;
  .text{
    font-weight: 400;
    font-size: .8rem
  }
}

.highlights-section {
  padding: 4rem 1rem;
  margin: 4rem -20px;
  background-color: #F7F7F7;
  /*.column {
    border-top: 1px solid #e09f7d;
    &:last-child,
    &:nth-last-child(2) {
      border-bottom: 1px solid #e09f7d;
    }
  }*/
}

@media (min-width: 768px) {
  .column {
    width: 48%;
  }
}

.description-text {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.back--btn {
  z-index: 2;
  position: relative;
  top: 12px;
  display: block;
}

.link {
  color: #0e3d4d;
}

.map-container {
  padding: 15px 20px;
  border: 1px solid #edebf2;
  border-radius: 0 0 20px 20px;
  margin-bottom: 1.5rem;
}

.icon {
  height: 1.15rem;
  width: 1.15rem;
}

.icon--md {
  height: 1.75rem;
  width: 1.75rem;
}

.booking-container {
  border-radius: 10px;
  position: sticky;
  top: 102px;
}

.button.is-large {
  height: 2.8rem;
  font-size: 1rem;
}

.rounded-image {
  height: 282px;
  object-fit: cover;
}

.title,
.subtitle {
  color: #0e3d4d;
}

.subtitle {
  font-family: "Playfair Display", serif; 
  font-weight: 600;
  font-size: 1.9rem;
}

.stop--image {
  height: 12rem;
  width: -webkit-fill-available;
  object-fit: cover;
  border-radius: 8px;
}

@media (min-width: 764px) {
  .stop--image {
    height: 20rem;
  }
}

@media (min-width: 1024px) {
  .stop--image {
    height: 18rem;
  }
}

.description, .description-text {
  font-size: 1rem;
  color: #0e3d4deb;
  font-weight: 300;
}

.tour-details {
  position: absolute;
  top: 24px;
  right: 18px;
  z-index: 2;
  .tag {
    color: #0e3d4d;
    background-color: #fff;
    padding: 4px 14px;
    border-radius: 5px;
    font-size: 15px;
  }
  .icon {
    margin-right: -6px;
    margin-left: -6px;
  }
}

.card-header {
  background-color: #0e3d4d;
  color: white;
  padding: 2rem;
  margin: 1rem 0;
}
.card-header-title{
  color: white;
}


.card-header-title,
.card-header-icon,
.card-content {
  padding: 0;
}

.languages-container {
  background-color: #f7fbff;
  padding: 12px;
  border-radius: 12px;
}

::v-deep {
  .card-content{
    background-color: rgba(48,117,140,.031372549);
      padding: 2rem;
  }
  
  ul {
    margin-block-end: -40px;
    margin-block-start: -16px;
  }

  .includes li {
    background: url("../assets/check--green.png") no-repeat;
    background-size: 20px 20px;
    padding-left: 28px;
    margin-top: -1rem;
  }
  .excludes li {
    background: url("../assets/times-red.png") no-repeat;
    background-size: 20px 20px;
    padding-left: 28px;
    margin-top: -1rem;
  }
  .carousel .carousel-items {
    margin: -20px !important;
    margin-top: -68px !important;
    margin-bottom: 0.75rem !important;
  }
  .faq .card-header {
    background-color: rgba(240, 248, 255, 0.631372549);
  }
  .vue-map {
    border-radius: 20px 20px 0 0;
  }
  .tag:not(body).tag--purple {
    border-radius: 50px;
    color: #fff;
    background-color: #7f7eff;
  }
  .tag:not(body).is-primary.is-light {
    color: var(--primary);
  }
  .b-image-wrapper > img {
    object-fit: cover;
  }
  .hero .image img {
    display: block;
    height: 22vh;
    border-radius: 7px;
    width: 100%;
  }
  .carousel .carousel-items {
    margin: 0px;
  }
  .columns {
    align-items: start;
  }
  .row {
    display: inline-flex;
  }
  .dropdown-content {
    background-color: white;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-bottom: 0;
    padding-top: 0;
  }
  .row.footer-mobile {
    display: block; //display: flex;
    //justify-content: space-between;
  }
  footer.modal-card-foot {
    background-color: white !important;
  }
}

.collapse-trigger {
  padding: 12px 0px !important;
}

.collapse .collapse-trigger .icon {
  transform: translateY(4px);
}

.collapse-content {
  margin-top: 12px;
}

.message p {
  color: #222222;
}

.message-body {
  color: #0e3d4d;
}

.page-footer {
  bottom: -1px;
  background-color: #fff;
  border-top: 1px solid rgb(29 45 83 / 7%);
  width: 100%;
  position: fixed;
  left: 0;
  padding: 12px 20px;
  backdrop-filter: blur(5px);
  .price {
    font-weight: bold;
    font-size: 1.25rem;
  }
  .columns {
    align-items: center;
  }
  .label {
    line-height: 17px;
    color: #4a5775;
    font-weight: 400;
    padding-bottom: 0;
    font-size: 0.8rem;
  }
  .label:not(:last-child) {
    margin-bottom: 0;
  }
  .button {
    height: 3.25rem;
  }
}

.media.review {
  margin: 42px 0px 24px 0px;
}

.no-reviews {
  text-align: center;
  margin: 72px 0px;
}

.review .rate {
  margin: 8px 0px 11px 0px;
}

.card {
  border-radius: 8px;
  padding: 32px;
  margin: 16px;
}

.title{
  //font-family: "Playfair Display", serif; 
  font-family: 'Miller Display', serif;
    font-size: 2.5rem !important;
    font-weight: 600;
}

@media (min-width: 1024px) {
  .rounded-image {
    height: 468px;
  }
}

::v-deep {
  @media (max-width: 1024px) {
    .carousel .carousel-items {
      width: 100vw;
    }
  }
}

.dropdown-item,
.dropdown .dropdown-menu .has-link a {
  margin: 0;
}

.info--container {
  background-color: #f6fbff;
  padding: 22px;
  border-radius: 17px;
}

.card .label {
  color: #7a7a7a;
  display: block;
  font-size: 1rem;
  font-weight: normal;
}

.level-item {
  align-items: end;
  justify-content: flex-end;
}

.navigate-back {
  margin-bottom: 32px;
  width: fit-content;
  cursor: pointer;
}

.margin {
  margin: 42px 0px;
}

.label {
  color: #7a7a7a;
  font-weight: 500;
  padding-bottom: 12px;
}

.card .title {
  padding-bottom: 32px;
}

.book-column {
  height: 100vh;
  margin-top: -3rem;
}

// ITINERARY
.card-content.itinerary {
  margin-top: 1rem;
}

.itinerary {
  .item-title {
    font-size: 1.2rem;
    line-height: normal;
  }
}

.rb-container ul.rb li:last-child {
  padding-bottom: 0.5rem;
}

.rb-container {
  margin: 24px;
  display: block;
  position: relative;
}

.rb-container ul.rb {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.rb-container ul.rb li {
  list-style: none;
  margin: auto;
  margin-left: 0;
  min-height: 50px;
  border-left: 5px dotted #dedede;
  padding: 0 0 50px 30px;
  position: relative;
}

.rb-container ul.rb li:last-child {
  border-left: 0;
  &::before {
    left: -9px;
  }
}

.rb-container ul.rb li::before {
  position: absolute;
  left: -14px;
  top: 0;
  content: " ";
  border: 9px solid #0e3d4d;
  border-radius: 500%;
  background: #fff;
  height: 24px;
  width: 24px;
  transition: all 500ms ease-in-out;
}

.rb-container ul.rb li:hover::before {
  border-color: #0e3d4dd1;
  transition: all 1000ms ease-in-out;
}

.item-title {
  font-weight: 600;
}

.item-title,
.item-subtitle {
  color: #0e3d4d;
}

::v-deep {
  .tag:not(body) {
    padding: 1rem;
  }
}
</style>
